var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{attrs:{"sm":"12","md":"3"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('h3',[(_vm.clickable)?_c('router-link',{attrs:{"to":{
                name: 'HackathonViewIdea',
                params: {
                  idea_id: _vm.id,
                  hackathon_id: _vm.hackathon_id,
                },
              }}},[_vm._v(_vm._s(_vm.name))]):_c('a',[_vm._v(_vm._s(_vm.name))]),_vm._v("   "),(_vm.is_creator)?_c('router-link',{attrs:{"to":{
                name: 'HackathonRudIdea',
                params: {
                  idea_id: _vm.id,
                  hackathon_id: _vm.hackathon_id,
                },
              }}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e()],1)]),_c('b-col',{staticClass:"text-right"})],1),_c('b-row',[_c('b-col',[(_vm.idea.hackathon.ideas_require_approval)?_c('badge',{attrs:{"rounded":true,"size":"md","type":_vm.approval_status_style}},[_vm._v(" "+_vm._s(_vm.idea.approval.state.display_name)+" ")]):_vm._e()],1)],1),_c('b-row',[_c('b-col',[_c('p',[_vm._v(_vm._s(_vm.description))])])],1),_c('b-row',[_c('b-col',[(
              (_vm.team.max_members > 0 && _vm.team.member_count > 0) ||
              _vm.is_part_of_team
            )?_c('p',[_vm._v(" Team "+_vm._s(_vm.team.member_count)+"/"+_vm._s(_vm.team.max_members)+"   "),(_vm.team.is_member)?_c('badge',{attrs:{"rounded":true,"size":"md","type":"info"}},[_vm._v(" Your Team ")]):_vm._e()],1):_vm._e(),(_vm.clickable)?_c('router-link',{attrs:{"to":{
              name: 'HackathonViewIdea',
              params: {
                idea_id: _vm.id,
                hackathon_id: _vm.hackathon_id,
              },
            }}},[_vm._v(" Join this team! ")]):_c('a',[_vm._v("Join this team!")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }